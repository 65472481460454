var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
class Map {
    constructor({ container, center, zoom }) {
        if (!container)
            throw new Error("Контейнер не определён");
        this.container = container;
        this.createMap(center, zoom);
    }
    static loadAPI() {
        if (!this.isLoading && !this.ymapIsReady) {
            this.loader = this.startLoad();
            return this.loader;
        }
        else
            return this.loader;
    }
    static startLoad() {
        return new Promise((resolve) => {
            this.isLoading = true;
            if (Map.ymapIsReady) {
                this.isLoading = false;
                resolve("loaded");
                return;
            }
            const script = document.createElement("script");
            script.src = `https://api-maps.yandex.ru/3.0/?apikey=${this.key}&lang=ru_RU`;
            script.addEventListener("load", () => __awaiter(this, void 0, void 0, function* () {
                yield ymaps3.ready;
                Map.ymapIsReady = true;
                this.isLoading = false;
                resolve("loaded");
            }));
            document.body.append(script);
        });
    }
    static createDefaultMarker() {
        const marker = document.createElement("div");
        marker.innerHTML = `
			<svg class="icon" width="25" height="38" viewBox="0 0 25 38" fill="none">
				<circle cx="12.5" cy="12.5" r="7.5" fill="white"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 0C19.4036 0 25 5.59644 25 12.5C25 14.7771 24.3929 16.9107 23.3273 18.7507C22.6169 19.9774 19.0078 26.2272 12.5 37.5C5.99217 26.2272 2.38307 19.9774 1.6727 18.7507C0.607143 16.9107 0 14.7771 0 12.5C0 5.59644 5.59644 0 12.5 0ZM12.5 7.14286C9.54133 7.14286 7.14286 9.54133 7.14286 12.5C7.14286 15.4587 9.54133 17.8571 12.5 17.8571C15.4587 17.8571 17.8571 15.4587 17.8571 12.5C17.8571 9.54133 15.4587 7.14286 12.5 7.14286Z" fill="#2F3643"/>
			</svg>`;
        marker.className = "default-map-marker";
        return marker;
    }
    createMap(center = [37.617635, 55.755814], zoom = 15) {
        this.ymap = new ymaps3.YMap(this.container, {
            location: {
                center,
                zoom,
            },
        }, [
            new ymaps3.YMapDefaultSchemeLayer({}),
            new ymaps3.YMapDefaultFeaturesLayer({ zIndex: 1800 }),
        ]);
    }
    setLocation(center, zoom = 15) {
        var _a;
        if (!this.ymap)
            return;
        (_a = this.ymap) === null || _a === void 0 ? void 0 : _a.setLocation({
            center,
            zoom,
        });
    }
    addDefaultMarker(coordinates) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const { YMapDefaultMarker } = yield ymaps3.import("@yandex/ymaps3-markers@0.0.1");
            (_a = this.ymap) === null || _a === void 0 ? void 0 : _a.addChild(new YMapDefaultMarker({
                coordinates,
            }));
        });
    }
    addMarker(coordinates, content, props) {
        if (!this.ymap)
            return;
        const marker = new ymaps3.YMapMarker(Object.assign({ coordinates, draggable: false }, props), content);
        this.ymap.addChild(marker);
    }
    addMarkers(coordinates, content) {
        coordinates.forEach((coords) => {
            this.addMarker(coords, content(coords));
        });
    }
}
Map.key = "f0c094b2-928e-442a-a626-641e01aa89c8";
Map.ymapIsReady = false;
Map.isLoading = false;
export default Map;
