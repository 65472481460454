import { createPopper } from "@popperjs/core";

export default (projects, places) => ({
  isTooltipHidden: true,
  projects: [],
  places: [],
  currentPlaceID: "",
  current: {
    title: "",
    square: "",
    status: "",
    projects: [],
  },
  init() {
    this.projects = JSON.parse(projects);
    this.places = JSON.parse(places);

    this.places.forEach((place) => {
      const placeElem = this.$refs.places.querySelector(`#${place.placeID}`);
      placeElem.classList.add(place.status.value);
    });
  },
  onPlaceEnter() {
    const id = this.$el.id;
    const currentPlace = this.places.find((place) => place.placeID == id);

    if (!currentPlace) {
      this.isTooltipHidden = true;
      return;
    }

    const projects = currentPlace.projects
      ? this.projects.filter((project) =>
          currentPlace.projects.includes(project.ID)
        )
      : [];

    this.current = {
      title: currentPlace.title,
      square: currentPlace.square,
      status: currentPlace.status,
      projects,
    };

    this.$nextTick(() => {
      this.isTooltipHidden = false;
      this.createTooltip({
        elem: this.$el,
        placement: "top",
        autoPlacements: ["top", "bottom"],
        offset: [0, -22],
      });
    });
  },
  createTooltip({ elem, placement, autoPlacements, offset = [0, 0] }) {
    const popper = createPopper(elem, this.$refs.tooltip, {
      placement,
      modifiers: [
        {
          name: "preventOverflow",
          options: {
            boundary: this.$root,
            padding: 5,
          },
        },
        {
          name: "flip",
          options: {
            fallbackPlacements: autoPlacements,
            allowedAutoPlacements: autoPlacements,
            boundary: this.$root,
          },
        },
        {
          name: "offset",
          options: {
            offset,
          },
        },
      ],
    });
    popper.forceUpdate();
    return popper;
  },
  onPlaceLeave(event) {
    const target = event.relatedTarget || event.target;
    if (!target.matches(".tooltip *, .tooltip, .places *, .places")) {
      this.isTooltipHidden = true;
      this.current = {
        name: "",
        specs: [],
        price: "",
        status: "",
        link: "",
        img: "",
      };
    }
  },
});
