export default () => ({
  currentSlide: 1,
  async init() {
    const { default: Swiper } = await import("../libs/Swiper");

    let thumbsSwiper = false;

    if (this.$refs.thumbsSwiper) {
      thumbsSwiper = new Swiper(this.$refs.thumbsSwiper, {
        slidesPerView: "auto",
        spaceBetween: 7,
        speed: 350,
      });
    }

    new Swiper(this.$refs.swiper, {
      speed: 500,
      spaceBetween: 40,
      navigation: {
        prevEl: this.$refs.prev,
        nextEl: this.$refs.next,
      },
      thumbs: thumbsSwiper
        ? {
            swiper: thumbsSwiper,
          }
        : false,
      on: {
        slideChange: (swiper) => {
          this.currentSlide = swiper.activeIndex + 1;
        },
      },
    });
  },
});
