import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";

Alpine.plugin(intersect);

import PhoneInputMask from "./PhoneInputMask";
Alpine.data("PhoneInputMask", PhoneInputMask);

import HousesMap from "./HousesMap";
Alpine.data("HousesMap", HousesMap);

import AboutSlider from "./AboutSlider";
Alpine.data("AboutSlider", AboutSlider);

import Anchor from "./Anchor";
Alpine.data("Anchor", Anchor);

import GallerySlider from "./GallerySlider";
Alpine.data("GallerySlider", GallerySlider);

import FancyboxGallery from "./FancyboxGallery";
Alpine.data("FancyboxGallery", FancyboxGallery);

import InfrastuctureSlider from "./InfrastuctureSlider";
Alpine.data("InfrastuctureSlider", InfrastuctureSlider);

import CirclePercentage from "./CirclePercentage";
Alpine.data("CirclePercentage", CirclePercentage);

import Accordion from "./Accordion";
Alpine.data("Accordion", Accordion);

import ContactsMap from "./ContactsMap";
Alpine.data("ContactsMap", ContactsMap);

import AboutProjectSlider from "./AboutProjectSlider";
Alpine.data("AboutProjectSlider", AboutProjectSlider);

import FullscreenGallery from "./FullscreenGallery";
Alpine.data("FullscreenGallery", FullscreenGallery);

import MaterialsSlider from "./MaterialsSlider";
Alpine.data("MaterialsSlider", MaterialsSlider);

Alpine.store("mainMenu", {
  isOpen: false,
});

Alpine.store("blocksChain", {
  items: [],
  set(blocksJSON) {
    this.items = JSON.parse(blocksJSON);
  },
  add(item) {
    this.items.push(item);
  },
});

Alpine.start();
