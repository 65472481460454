export default () => ({
  swiper: null,
  currentIndex: 0,
  async init() {
    const { default: Swiper } = await import("../libs/Swiper");

    this.swiper = new Swiper(this.$refs.swiper, {
      speed: 900,
      navigation: {
        prevEl: this.$refs.prev,
        nextEl: this.$refs.next,
      },
      on: {
        slideChange: (swiper) => {
          this.currentIndex = swiper.activeIndex;
        },
      },
    });
  },
  slideTo(index) {
    this.currentIndex = index;
    this.swiper.slideTo(index);
  },
});
