export default (percentage) => ({
  isAnimating: false,
  init() {
    const length = this.$refs.circle.getTotalLength();
    this.$refs.circle.style.strokeDasharray = length;
    this.$refs.circle.style.strokeDashoffset = length;
    this.$refs.circle.style.setProperty(
      "--dashoffset",
      ((100 - parseInt(percentage)) / 100) * length
    );
  },
});
