export default (initialSlide = 0) => ({
  currentSlide: 1,
  swiperRows: [],
  async init() {
    const { default: Swiper } = await import("../libs/Swiper");

    const swipers = [];

    this.swiperRows.forEach((row) => {
      const instance = new Swiper(row, {
        speed: 400,
      });
      swipers.push(instance);
    });

    new Swiper(this.$refs.swiper, {
      speed: 400,
      initialSlide,
      on: {
        slideChange: (swiper) => {
          this.currentSlide = swiper.activeIndex + 1;
        },
      },
      controller: {
        control: swipers,
      },
    });
  },
});
