export default () => ({
  currentSlide: 1,
  async init() {
    const { default: Swiper } = await import("../libs/Swiper");

    new Swiper(this.$refs.swiper, {
      speed: 900,
      navigation: {
        prevEl: this.$refs.prev,
        nextEl: this.$refs.next,
      },
      on: {
        slideChange: (swiper) => {
          this.currentSlide = swiper.activeIndex + 1;
        },
      },
    });
  },
});
