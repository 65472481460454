export default () => ({
  currentSlide: 1,
  async init() {
    const { default: Swiper } = await import("../libs/Swiper");

    new Swiper(this.$refs.mainSwiper, {
      speed: 950,
      navigation: {
        prevEl: this.$refs.prev,
        nextEl: this.$refs.next,
      },
      on: {
        slideChange: (swiper) => {
          this.currentSlide = swiper.activeIndex + 1;
        },
      },
    });
  },
  async old_init() {
    const { default: Swiper } = await import("../libs/Swiper");

    const setThumbsClasses = (swiper) => {
      swiper.slides.forEach((slide, index) => {
        if (index < swiper.activeIndex) {
          slide.classList.remove("next-slide");
          slide.classList.add("prev-slide");
        } else if (index > swiper.activeIndex) {
          slide.classList.remove("prev-slide");
          slide.classList.add("next-slide");
        } else {
          slide.classList.remove("prev-slide");
          slide.classList.remove("next-slide");
        }
      });
    };

    const thumbsSwiper = new Swiper(this.$refs.thumbsSwiper, {
      speed: 600,
      slidesPerView: "auto",
      navigation: {
        prevEl: this.$refs.prev,
        nextEl: this.$refs.next,
      },
      on: {
        init: setThumbsClasses,
        slideChange: setThumbsClasses,
      },
      spaceBetween: 7,
      breakpoints: {
        1280: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
    });

    new Swiper(this.$refs.mainSwiper, {
      speed: 950,
      thumbs: {
        swiper: thumbsSwiper,
      },
      on: {
        slideChange: (swiper) => {
          this.currentSlide = swiper.activeIndex + 1;
        },
      },
    });
  },
});
